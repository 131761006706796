:root {
    --border-radius: 3px;
}

#root {
    height: 100vh;
}

body {
    background-color: var(--surface-300);
    font-size: 15px;
    line-height: 1.2rem;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

// TODO: needed for wordpress so that the tooltip doesn't cover the button
#wpbody-content,
.wp-admin {
    .p-tooltip {
        pointer-events: none !important;
        transform: translateY(-25px) !important;
    }
}

.verify-email{
    max-width: 600px;
    margin: 0 auto;
}